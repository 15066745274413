const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const generateQueryParamsForRazarPay = (json) => {
  const requiredParams = {
    key: json?.paymentGatewayRequestParamMap?.client_id || null,
    amount: json?.paymentGatewayRequestParamMap?.amount_due || 0,
    currency: json?.paymentGatewayRequestParamMap?.currency || null,
    name: `${json?.userProfile?.firstName} ${json?.userProfile?.lastName}`,
    email: json?.userProfile?.emailAddress,
    contact: json?.userProfile?.phoneNumber,
    description:
      json?.donation?.lineItems?.[0]?.donationItem?.description || "",
    order_id: json?.paymentGatewayRequestParamMap?.id || null,
    origin: json?.paymentGatewayRequestParamMap?.origin || null,
  };
  let url = "?";
  Object.entries(requiredParams)?.map(([k, v], index) => {
    if (index !== -1) {
      url += `${k}=${v}&`;
    }
    return k;
  });
  return url;
};

export const postRazorpayUrl = (path, params, method = "post", target = "") => {
  const form = document.createElement("form");
  form.method = method;
  form.action = path;
  form.target = target;
  Object.keys(params).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value =
        params[key] instanceof Object
          ? JSON.stringify(params[key])
          : params[key];
      form.appendChild(hiddenField);
    }
  });
  document.body.appendChild(form);
  form.submit();
};

export default loadScript;
