import React, { useCallback, useEffect, useRef, useState } from "react";
import loadScript, { postRazorpayUrl } from "sites-common/utils/loadScript";
import { Spinner } from "theme-ui";

import { StringParam, useQueryParam } from "use-query-params";
import SEO from "../components/seo";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { razorPayCheckoutLib, paymentServiceUrl } = getMandatoryEnv([
  "razorPayCheckoutLib",
  "paymentServiceUrl",
]);

const RazorPayPG = () => {
  const [order_id] = useQueryParam("order_id", StringParam);
  const [amount] = useQueryParam("amount", StringParam);
  const [currency] = useQueryParam("currency", StringParam);
  const [origin] = useQueryParam("origin", StringParam);
  const [key] = useQueryParam("key", StringParam);
  const [name] = useQueryParam("name", StringParam);
  const [email] = useQueryParam("email", StringParam);
  const [contact] = useQueryParam("contact", StringParam);
  const [description] = useQueryParam("description", StringParam);
  const [loading, setLoading] = useState(true);
  const PAYMENTS_SERVICE_URL = paymentServiceUrl;
  const paymentFailed = useRef(false);

  const failureCall = useCallback(() => {
    const param = {
      error: {
        description: "Transaction has been cancelled by user",
        metadata: {
          order_id,
        },
      },
      amount,
      currency,
    };

    postRazorpayUrl(`${PAYMENTS_SERVICE_URL}/open/razorpay/redirectAck`, param);
  }, [amount, currency, order_id, PAYMENTS_SERVICE_URL]);

  const callRedirect = useCallback(
    (res) => {
      postRazorpayUrl(`${PAYMENTS_SERVICE_URL}/open/razorpay/redirectAck`, res);
    },
    [PAYMENTS_SERVICE_URL]
  );

  useEffect(() => {
    const displayRazorPay = async () => {
      const res = await loadScript(razorPayCheckoutLib);
      if (!res) {
        alert("Razor pay SDK failed to load");
        return;
      }

      const options = {
        key,
        amount,
        currency,
        name,
        description,
        image:
          "https://cdn-web.heartfulness.org/en/wp-content/uploads/2019/04/05114150/LTM-logo_updated.png",
        order_id,
        // callback_url: `${PAYMENTS_SERVICE_URL}/open/razorpay/redirectAck`,

        handler: (response) => {
          paymentFailed.current = false;
          callRedirect(response);
        },

        prefill: {
          name,
          email,
          contact,
        },
        retry: {
          enabled: false,
        },
        modal: {
          ondismiss: () => {
            if (!paymentFailed.current) {
              paymentFailed.current = false;
              failureCall();
            }
          },
        },
        notes: {
          origin,
        },
        theme: {
          color: "#3399cc",
        },
      };
      // const window = window;
      const paymentObject = new window.Razorpay(options);

      paymentObject.on("payment.failed", (response) => {
        paymentFailed.current = true;
        callRedirect({
          error: {
            description: response?.error?.description,
            metadata: { order_id },
          },
          amount,
          currency,
        });
      });
      setLoading(false);
      paymentObject.open();
    };

    displayRazorPay();
  }, [
    amount,
    callRedirect,
    contact,
    email,
    failureCall,
    name,
    order_id,
    currency,
    PAYMENTS_SERVICE_URL,
    origin,
    key,
    description,
  ]);

  return (
    <>
      <SEO title="Donation - India Donation" />
      {loading && (
        <div
          style={{
            height: "100vh !important",
            zIndex: "100003 !important",
            display: "block",
            background: "rgba(255,255,255)",
            textAlign: "center",
          }}
        >
          <b sx={{ fontSize: "24px" }}>Razorpay Secure Payment</b>
          <p sx={{ margin: "10px 0" }}>
            <Spinner /> Loading...
          </p>
        </div>
      )}
    </>
  );
};

export default RazorPayPG;
